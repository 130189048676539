<template>
	<b-modal  size="xl" ref="modalAddOrder">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('action.notifier_centre_stockage') }}</h2>
		</template>
		<template slot="default">
			<div class="form-group">
				<label>{{ $t('monte.reservation.commentaire') }}</label>
				<b-form-textarea
					id="form_comment"
					v-model="form.comment"
					:placeholder="$t('monte.reservation.saisir_commentaire')"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>


			<div class="col-12" v-if="form_error">
				<ErrorAlert :messageI18n="form_error" />
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.sauvegarder') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common'
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"

	export default {
		name: 'ModalAddOrder',
        mixins: [
			MonteReservationOrder
        ],
		data () {
			return {
				processing: false,
                form_error: '',
                resa_ids: null,
                form:{
                    comment: '',
                    resa_ids: null
                }
			}
		},
		methods: {
			async checkForm() {
				let errors = []
				this.processing = true
                const ids = this.form.resa_ids.map(r => r.id)
			    let promise = null
                promise = this.notifyOrderExpediteur(ids, this.form.comment)

                return promise
					.then(_ => {
						this.closeModal()
						this.form.comment='';
						this.$emit('ok')
					})
					.catch(_ => {

					})
					.finally(() => {
						this.processing = false
					})

			},
            openModal(resa_ids) {
                this.form.resa_ids = resa_ids;
				this.$refs.modalAddOrder.show()
			},
			closeModal() {
				this.$refs.modalAddOrder.hide()
			},
		},
		components: {
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
		}
	}

</script>